/* Global */
body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  background-color: #4DD0E1; // blue
  margin: 0;
  padding: 0;
  overflow: hidden;
}


.heading-wrapper {
  position: absolute;
  left: 50%;

  bottom: 0;
  transform: translate(-50%,-250%);
  z-index: 333;
  font-size: 21px;
}

#social-wrapper {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,-150%);
  z-index: 333;

  a {
    background-color: #ffffff;
    padding: 10px 15px;
    margin-right: 15px;
  }

  @media only screen and (max-width: 600px) {
    left: 0;
    bottom: 0;
    transform: translate(50%,-150%);
  }
}
